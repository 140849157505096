.container {
  width: 100%;
  padding: 16px;
}

.login_form {
  width: 100%;
}

.login_tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;

  label {
    // this is ant-radio-button-wrapper
    flex: 1; // make it grow
    overflow: hidden; // "local auth..." for sm screen
    // and the radio look more like tab | won't work w/o ↙
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    // css-dev-only-do-not-override | won't work
    //&.ant-radio-button-wrapper-checked {
    //  border-bottom: unset;
    //}
    // ... styled-components could do the trick
  }
}

.input_item {
  width: 100%;
  margin: 0;
}

.providerButton {
  width: 100%;
}

.divider {
  margin: 0 !important;

  &::before, &::after {
    border-color: rgba(0, 0, 0, 0.3) !important;
  }
}

.loginButton {
  width: 100%;
}
