.backgroundAccent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: radial-gradient(at 35% 40%, #1890ff 15%, transparent 35%),
  radial-gradient(at 65% 50%, #40a9ff 5%, transparent 45%),
  radial-gradient(at 25% 60%, #69c0ff 10%, transparent 30%),
  radial-gradient(at 75% 65%, #91d5ff 0%, transparent 20%),
  radial-gradient(at 55% 75%, #0050b3 0%, transparent 35%);

  background-blend-mode: saturation;
  opacity: 0.25;
  overflow: hidden;
  pointer-events: none;
  object-fit: cover;
  filter: blur(140px);
}

.page {
  background: white;
  position: relative;
}