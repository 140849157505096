@use "./PermissionItemForm.module";

.spacesForm {
  margin-bottom: 24px;
}

.addSpaceButton {
  margin-left: auto;
}

.addPermissionButton {
  margin-left: auto;
}

.permissionRow {
  @include PermissionItemForm.permissionRow;
}

.permissionFormItem {
  @include PermissionItemForm.permissionFormItem;
}

.buttonPlaceholder {
  visibility: hidden;
  height: 0;
}

.header {
  margin-top: 8px !important;
}

.infoText {
  margin-bottom: 8px !important;
}