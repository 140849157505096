@mixin permissionRow {
  flex: 1;
}

@mixin permissionFormItem {
  margin-bottom: 0;
  flex: 1;
}

.permissionRow {
  @include permissionRow;
}

.permissionFormItem {
  @include permissionFormItem;
}

.inputButtonFormItem {
  margin: 0;
  padding: 0;

  :global(.ant-form-item-control-input) {
    min-height: unset;
  }
}

.inputButton {
  height: 22px !important;
  width: 22px !important;
}