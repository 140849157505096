$form_max_width: 360px;

.main {
  min-height: 100vh;
  background: transparent;
}

.header {
  background: transparent;
}

.content {
  display: flex;
  z-index: 10;
}

.formContainer {
  flex-grow: 1;
  padding: 32px;
}

.form {
  max-width: $form_max_width;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 32px;
}

.logo {
  width: 64px;
  height: 64px;
  display: block;
}

.title {
  margin: 0;
}